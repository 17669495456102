.exelite__content {
    display: flex;
    color: #ffffff;
}

.cont.mobile {
    display: none;
}

.exelite__container, .exelite__content {
    height: 100%;
}

.exelite__left__content {
    width: 720px;
    padding: 0 20px;
    height: 100%;
    padding-bottom: 30px;
}

.exelite__right__content {
    width: 720px;
    background: linear-gradient(180deg, #382A52 0%, #825645 100%);
}

.top__content {
    font-size: 32px;
    font-family: 'EuronicsHeavy';
    font-weight: 800;
    color: #ffffff;
    text-align: center;
    padding-bottom: 75px;
    max-width: 580px;
    margin: 45px auto 0;
    letter-spacing: 0.64px;
    padding-left: 20px;
    padding-right: 20px;
}


.top__content div:first-child {
    margin-bottom: 24px;
}

.middle__content {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    max-width: 580px;
    margin: 0 auto;
    padding: 37px 0;
    padding-left: 20px;
    padding-right: 20px;
}

.middle__content h2 {
    color: #ffffff;
    font-size: 30px;
    margin: 0;
    font-family: EuronicsHeavy;
    font-weight: 800;
    margin-bottom: 9px;
}

.middle__content div {
    text-align: center;
}

.middle__content p {
    margin: 0;
    font-size: 20px;
    font-family: Euronics-Bold;
}

hr {
    margin: 0;
    border: 0;
    border-top: 0;
    height: 1px;
    background: #ffffff;
}

.bottom__content {
    margin-top: 68px;
    margin-bottom: 67px;
    padding: 0 20px;
}

.bottom__content h2 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 42px;
    font-family: EuronicsRegular;
}

.bottom__content .bottom__content__images {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 580px;
    text-align: center;
    margin: 0 auto;
    gap: 37px;
}

.cont {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 48px;
}

.cont.last {
    justify-content: flex-start;
    margin-top: 10px;
}

.taj {
    margin-left: 18px;
}

.jabra {
    margin-left: -22px;
}

.yelp {
    margin-right: 21px;
}

.tagLine {
    font-size: 28px;
    font-family: 'EuronicsHeavy';
    line-height: 42px;
    letter-spacing: 0.56px;
    background: linear-gradient(360deg, #382A52 0%, #825645 99%);
    background: -webkit-linear-gradient(360deg, #382A52 0%, #825645 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.exelite__left__inner {
    max-width: 520px;
    margin: 0 auto;
}
.exelite__left__inner .logo img {
    margin-top: 45px;
    margin-bottom: 34px;
    width: 250px;
}
.exelite__left__inner .logo  {
   text-align: center;
   margin-bottom: 30px;
}

@media (max-width: 1241px) {
    .top__content {
        font-size: 25px;
        padding-bottom: 50px;
    }  
}

@media(max-width: 1038px) {
    .exelite__right__content {
        width: 500px;
        flex: 50%;
    }
        .exelite__left__content {
         width: 450px;
    }
        .cont img {
            width: 7vw;
        }
        .cont .toyota img {
            width: 11vw;
        }
    
        .cont .jellyfish img,  .cont .delloite img,  .cont .peterhan img  {
            width: 11vw;
        }
         .middle__content  {
            margin: 30px auto;
        }
    }
    
    @media(max-width: 967px) {
        .middle__content h2 {
            font-size: 25px;
        }
        .middle__content p {
            font-size: 18px;
        }
    }
    
    @media(max-width: 916px) {
    .exelite__right__content {
        width: 490px;
    }
        .exelite__left__content {
         width: 450px;
        }
    }
    

    @media (max-width: 874px) {
        .exelite__content {
            flex-direction: column;
        }
        .exelite__right__content, .exelite__left__content {
            width: auto;
            }
        .exelite__left__content {
        height: auto;
    }
        .cont img,   .cont .toyota img , .cont .jellyfish img,  .cont .delloite img,  .cont .peterhan img  {
        width: auto;
    }
    .taj {
        margin-left: 0;
    }
    } 

    @media (max-width: 564px) {
        .cont.desktop {
            display: none;
        }
            .cont.mobile {
            display: flex;
                flex-wrap: wrap;
        }
        .taj {
            width: 50px;
        }
        .cont.mobile {
            justify-content: center;
        }
    }

    @media (max-width: 541px) {
        .cont.mobile div {
            width: 120px;
        }
    }

    @media (max-width: 422px) {
        .middle__content {
       flex-direction: column;
       }
       .middle__content {
       margin: 0 auto;
       padding: 30px;
   }
       .middle__content h2 {
       font-size: 32px;
           margin-bottom:0;
       }
       .middle__content p {
       font-size: 22px;
   }
       .middle__content .first, .middle__content .second, .middle__content .third {
           margin-bottom: 30px;    
       }
   }

   @media (max-width: 377px) {
    .cont.mobile div {
        width: 100px;
    }
    .cont.mobile  {
       justify-content: space-between;
       max-width: 300px;
       margin: 0 auto;
    }
}

@media (max-width: 362px) {
    .top__content div {
        font-size: 22px;
            margin-bottom:0;
        }
}
