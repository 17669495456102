 body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'EuronicsBlack';
  src: url('./fonts/Euronics-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Euronics-Bold';
  src: url('./fonts/Euronics-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'EuronicsHeavy';
  src: url('./fonts/Euronics-Heavy.otf') format('opentype');
}
@font-face {
  font-family: 'EuronicsRegular';
  src: url('./fonts/euronics-regular.woff') format('woff');
}